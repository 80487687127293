<template>
  <div class="create-campaign">
    <h4 v-if="campaign.campaignId">Update Campaign</h4>
    <h4 v-else>Create Campaign</h4>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="infoLoading"
    ></v-progress-linear>

    <v-form
      v-model="valid"
      lazy-validation
      ref="campaignForm"
      v-else
    >
      <v-row>
        <v-col cols="12">
          <label class="label">Campaign Name</label>
          <v-text-field
            v-model="campaign.campaignName"
            :rules="campaignRules.campaignName"
            placeholder="Enter campaign name"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="label">The platform you want to choose for your promotion</label>
          <v-select
            :items="platformList"
            @change="changePlatform"
            :rules="campaignRules.platform"
            label="Select Platform"
            v-model="campaign.platform"
            item-text="label"
            item-value="value"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <label class="label">The Product or App you want to promote</label>
          <v-text-field
            v-model="campaign.productLink"
            :rules="campaignRules.productLink"
            placeholder="Enter Product/App link"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="label">Content Type </label>
          <v-select
            :disabled="!this.campaign.platform ? true : false"
            :items="this.campaign.platform === 1 ? contentYoutube : contentInstagram"
            label="Select Content Type"
            v-model="campaign.contentType"
            :rules="campaignRules.contentType"
            item-text="label"
            item-value="value"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <label class="label">Campaign Type </label>
          <v-select
            :items="campaignTypeList"
            item-text="label"
            item-value="value"
            label="Select Campaign Type"
            v-model="campaign.campaignType"
            :rules="campaignRules.campaignType"
            solo
          ></v-select>
        </v-col>

        <v-col cols="12">
          <h3>Campaign Brief</h3>
        </v-col>

        <v-col cols="6">
          <label class="label">Categories </label>
          <v-select
            :disabled="!this.campaign.platform ? true : false"
            :items="this.campaign.platform === 1 ? categoryList : instaCategoryList"
            label="Select Category"
            multiple
            v-model="campaign.category"
            :rules="campaignRules.category"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <label class="label">Language </label>
          <v-select
            :items="languageList"
            label="Select Language"
            multiple
            v-model="campaign.language"
            :rules="campaignRules.language"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <label class="label">Gender </label>
          <v-select
            :items="genderList"
            multiple
            label="Select Gender"
            v-model="campaign.gender"
            :rules="campaignRules.gender"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <label class="label">Follower Range </label>
          <div class="d-flex align-center justify-center">
            <v-text-field
              v-model="campaign.minimumRange"
              placeholder="Minimum"
              solo
              :rules="campaignRules.minimumRange"
            ></v-text-field>
            <div
              class="label text-center"
              style="width: 50px; height: 40px;"
            >To</div>
            <v-text-field
              v-model="campaign.maximumRange"
              :rules="campaignRules.maximumRange"
              placeholder="Maximum"
              solo
            ></v-text-field>
          </div>
        </v-col>

        <v-col cols="6">
          <label class="label">Campaign Duration </label>
          <v-dialog
            ref="dialog"
            v-model="datepickerDialog"
            :return-value.sync="campaign.duration"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Campaign Duration"
                append-icon="mdi-calendar"
                readonly
                solo
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="campaign.duration"
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="datepickerDialog = false"
              >Cancel</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(campaign.duration)"
              >OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="6">
          <label class="label">Budget</label>
          <v-text-field
            :rules="campaignRules.budget"
            v-model="campaign.budget"
            placeholder="Enter Budget"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="label">Deliverable</label>
          <v-textarea
            v-model="campaign.deliverable"
            rows="4"
            placeholder="Write your campaign deliverable"
            solo
            hide-details
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <label class="label">Description</label>
          <v-textarea
            v-model="campaign.description"
            rows="4"
            placeholder="Write your campaign description"
            solo
            hide-details
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          class="text-right mt-4 mb-4"
        >
          <v-btn
            depressed
            dark
            large
            color="primary"
            style="width: 252px;"
            @click="updateCampaign()"
            :loading="loading"
            v-if="campaign.campaignId"
          >Update Campaign</v-btn>
          <v-btn
            depressed
            dark
            large
            color="primary"
            style="width: 252px;"
            @click="submitCampaign()"
            :loading="loading"
            v-else
          >Submit Campaign</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import constantData from '@/utils/constant'
import { createCampaign, getInfo, updateCampaign } from '@/api/campaign'

export default {
  data () {
    return {
      infoLoading: false,
      valid: true,
      languageList: constantData.language,
      categoryList: constantData.category,
      instaCategoryList: constantData.instaCategoryList,
      contentYoutube: constantData.contentYoutube,
      contentInstagram: constantData.contentInstagram,
      campaignTypeList: constantData.campaignTypeList,
      platformList: constantData.platformList,
      genderList: constantData.genderList,
      loading: false,
      datepickerDialog: false,
      campaign: {
        duration: []
      },
      campaignRules: {
        campaignName: [
          v => !!v || 'Please add a campaign name'
        ],
        platform: [
          v => !!v || 'Please select platform'
        ],
        productLink: [
          v => !!v || 'Product link is required',
          v => /[-a-zA-Z0-9@:%_/+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&//=]*)?/gi.test(v) || 'Invalid product link'
        ],
        contentType: [
          v => !!v || 'Please select content type'
        ],
        minimumRange: [
          v => !!v || 'Please enter follower minimum range',
          v => /^[+|\d+][0-9]{0,11}$/.test(v) || 'Digit should be less than 13 (Use Number)'
        ],
        maximumRange: [
          v => !!v || 'Please enter follower maximum range',
          v => /^[+|\d+][0-9]{0,11}$/.test(v) || 'Digit should be less than 13 (Use Number)'
        ],
        budget: [
          v => !!v || 'Please enter budget',
          v => /^[+|\d+][0-9]{0,11}$/.test(v) || 'Digit should be less than 13 (Use Number)'
        ],
        campaignType: [
          v => !!v || 'Please select compaign type'
        ],
        category: [
          v => (!!v && !!v.length) || 'Please select category'
        ],
        language: [
          v => (!!v && !!v.length) || 'Please select language'
        ],
        gender: [
          v => (!!v && !!v.length) || 'Please select gender'
        ]
      }
    }
  },
  computed: {
    dateRangeText () {
      return this.campaign.duration.join(' ~ ')
    }
  },
  mounted () {
    if (this.$route.params.campaignId) {
      this.getCampaignDetails()
    }
  },
  methods: {
    changePlatform () {
      this.campaign.contentType = null
      this.campaign.category = []
    },
    async getCampaignDetails () {
      this.infoLoading = true
      const dataQuery = {
        campaignId: this.$route.params.campaignId,
        platform: this.$route.params.platform
      }
      const data = await getInfo(dataQuery)
      this.campaign = {
        campaignId: data.campaign.campaign_id,
        campaignName: data.campaign.campaign_name,
        platform: data.campaign.promo_platform,
        productLink: data.campaign.product_link,
        contentType: data.campaign.content_type,
        campaignType: data.campaign.campaign_type,
        category: data.campaign.category.split(','),
        language: data.campaign.language.split(','),
        gender: data.campaign.gender.split(','),
        minimumRange: data.campaign.min_follower,
        maximumRange: data.campaign.max_follower,
        duration: [this.dateFormat(data.campaign.start_date), this.dateFormat(data.campaign.end_date)],
        budget: data.campaign.budget,
        deliverable: data.campaign.deliverable,
        description: data.campaign.campaign_desc
      }
      this.infoLoading = false
    },
    async submitCampaign () {
      if (this.$refs.campaignForm.validate()) {
        this.loading = true
        this.campaign.language = this.campaign.language.join()
        this.campaign.gender = this.campaign.gender.join()
        this.campaign.category = this.campaign.category.join()
        const data = await createCampaign(this.campaign)
        this.loading = false
        if (data.success) {
          this.$snackbar({
            message: data.message
          })
          this.$router.push('/campaign/list')
        } else {
          this.$snackbar({
            message: data.message,
            color: 'error',
            timeout: 5000
          })
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        console.log('error submit!!')
        return false
      }
    },
    async updateCampaign () {
      if (this.$refs.campaignForm.validate()) {
        this.loading = true
        this.campaign.language = this.campaign.language.join()
        this.campaign.gender = this.campaign.gender.join()
        this.campaign.category = this.campaign.category.join()
        const data = await updateCampaign(this.campaign)
        this.loading = false
        if (data.success) {
          this.$snackbar({
            message: data.message
          })
          // this.$router.push('/campaign/list')
        } else {
          this.$snackbar({
            message: data.message,
            color: 'error',
            timeout: 5000
          })
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        console.log('error submit!!')
        return false
      }
    },
    dateFormat (date) {
      if (!date) return ''
      var d = new Date(date)
      var formattedDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
      return formattedDate
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #6b6b6b;
    margin: 10px 0 0;
  }
  .v-text-field.v-text-field--enclosed.v-text-field--solo.v-select {
    margin: 5px 0 0;
  }
  .v-input >>> .theme--light.v-icon {
    font-size: 24px !important;
    color: #f54153 !important;
  }
</style>
